import React from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import {Products} from "../pages/StaticProductsData";

export default function Slider() {
  return (
    <React.Fragment>
      <section className="clean-block slider dark">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">KAIROS AURA Products</h2>
            <p>We deliver affordable medications that meet various medical needs, our aim is to be a leader in the pharmaceutical industry in india and develop top quality, cost-effective medications which improve the lives of patients.</p>
          </div>
          <AliceCarousel autoPlay autoPlayInterval="3000" infinite autoHeight="false" >
            {Products.map((item,index)=>(
              <img className="w-65 h-150 d-block" src={item.image} alt={item.name} key={index}/>
            ))}
          </AliceCarousel>
        </div>
      </section>
    </React.Fragment>
  );
}
