import React from 'react';
import {Products} from '../pages/StaticProductsData';

export default function ProductGallary() {
    return (
        <div>
            <div className="block-heading">
                <h2 className="text-info align-center">Product Gallery</h2>
                <div className="row justify-content-center">
                    {Products?.map((item,index) => (
                        <div className="col-sm-6 col-lg-4 cus-p-5" key={index}>
                            <div className="card clean-card text-center">
                                <img src={item?.image} className="card-img-top w-100 h-box d-block" alt={item?.name} />
                                <div className="card-body info cus-text-box">
                                    <h4 className="card-title">{item?.name}</h4>
                                    <p className="card-text">
                                        {item?.about}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
