import './App.css';
import Seo from './SEO';
import Navbar from './components/Navbar';
import Main from "./components/Main";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <div className="body">
        <Seo/>
        <Navbar />
        <Main/>
        <Footer />
      </div>
    </>
  );
}

export default App;
