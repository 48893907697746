import React from "react";
import { Link } from "react-router-dom";
import Productimg from "./Productimg";
export default function Navbar() {
  return (
    <div>

      <nav className="navbar navbar-light navbar-expand-lg fixed-top shadow-sm bg-white clean-navbar">
        <div className="container">

          <a className="navbar-brand logo" href="/">
            <img className="cus-logo" src={Productimg.KAIROS_AURA_logo} alt="logo" />
            {"  "}KAIROS AURA PHARMA
          </a>
          <button
            data-toggle="collapse"
            className="navbar-toggler"
            data-target="#navcol-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navcol-1">
            <ul className="nav navbar-nav ml-auto">
              <li className="nav-item" role="presentation">
                <Link className="nav-link active" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link className="nav-link" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item" role="presentation">
                <Link className="nav-link" to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
