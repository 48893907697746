import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Slider from "../components/Slider";
import ContactUs from "../components/ContactUs";
import ProductImg from "../components/Productimg";
import ProductGallary from "../components/ProductGallary";

const Home = () => {
  return (
    <React.Fragment>
      <div className="body">
        <Navbar />
        <main className="page landing-page mt-5">
          <Banner />
          <section className="clean-block clean-info dark">
            <div className="container">
              <div className="block-heading">
                <h2 className="text-info">Info</h2>
                <p>We take this opportunity to introduce ourselves as a professionally managed pharmaceutical company,which commits to all pharmaceuticals requirement under one roof. Kairos Aura pharmaceuticals offers an exclusive range of formulation in healthcare from tablet, capsule and other neutraceuticals products to newer drugs.Our vision as an organization is to achieve value driven leadership by managing and providing good services to our clients through consistent hard work.</p>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img
                    className="img-thumbnail"
                    src={ProductImg.kidney} alt="prodImg"
                  />
                </div>
                <div className="col-md-6">
                  <h3>As one of the leading pharmaceutical companies, we are committed towards making the world a happier and healthier place by ensuring that our products serve mankind across different geographies.</h3>
                  <div className="getting-started-info">
                    <p>We endeavor to achieve the best quality standards in the pharmaceutical industry in india.Our total Quality management system adheres to regulations and we aim to developing novel and differentiating scientific solutions through innovative products which are designed to improve the quality of life.</p>
                  </div>
                  <button
                    className="btn btn-outline-primary btn-lg"
                    type="button"
                  >
                    Join Now
                  </button>
                </div>
              </div>
            </div>
          </section>

          <Slider />
          <ProductGallary />
          <ContactUs />
        </main>
      </div>
    </React.Fragment>
  );
}

export default Home;
