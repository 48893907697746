import React from "react";

const Banner = () => {
  return (
    <React.Fragment>
      <div className="row ml-0 ">
        <section
          className="clean-block clean-hero w-100 section-bg-image"
          style={{ height: "650px" }}
        >
          <div className="text ">
            <h1>KAIROS AURA PHARMACEUTICALS</h1>
            <p>KAIROS AURA PHARMACEUTICALS with just few products and continuously progressing under the table guidance of our Managing Experts. We are engaged in manufacturing and supplying tablets related to Nephrology.KAIROS AURA PHARMACEUTICALS is licensed by the drug controller for the marketing of pharmaceutical products.We have a spacious Godown for the storage of pharmaceutical products With experienced Packaging & Storage Managers where the products have been displayed and kept very properly with Latest Packing available nationwide.</p>
            <a className="btn btn-outline-light btn-lg" href="/about">
              Learn More
            </a>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};
export default Banner;
