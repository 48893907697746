import React from "react";
import Navbar from "../components/Navbar";

function ContactUsPage() {
  return (
    <React.Fragment>
      <div className="body">
        <Navbar />
        <main className="page landing-page mt-5">
            <section className="clean-block clean-form dark">
              <div className="container">
                <div className="block-heading">
                  <h2 className="text-info">Contact Us</h2>
                  <p className="text-justify w-100 pb-4">KAIROS AURA PHARMACEUTICALS provides 24 hour customer support, to cater the needs of our valued clients located all over India. Below is the contact details you can contact anytime with our experts. </p>
                  
                  <div className="contact-text">
                    <div className="con-text">
                      <p><a href="mailto:aurakairospharma20@gmail.com"> aurakairospharma20@gmail.com</a></p>
                      <p> +91 7846885019</p>
                      <h6> </h6>
                    </div>
                  </div>
                </div>
                <form>
                  <div className="form-group">
                    <label>Name*</label>
                    <input className="form-control" type="text" />
                  </div>
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input className="form-control" type="number" />
                  </div>
                  <div className="form-group">
                    <label>Email*</label>
                    <input className="form-control" type="email" />
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea className="form-control"></textarea>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary btn-block" type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </section>
        </main>
      </div>
    </React.Fragment>
  );
}

export default ContactUsPage;
