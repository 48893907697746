import React from "react";
import { Helmet } from "react-helmet";
const Seo = () => {
    return (
        <>
            <Helmet>
                <title>Kairos Aura Pharma</title>
                <meta name="theme-color" content="#000000" />
                <meta name="keywords" content="Kairos 90 Kairos Alpha Nephrosis Kaira D3 Aura Plus kaira-Q10 Aleska-DSR NYRASIS AURA PLUS kaira Q10 Aleska DSR SYNA-400 Neurocos Forte, TIJOFI-TS 100 TIJOFI-TS 10 TIJOFI-TS 20 SLOBIS LONG  AURA PRO RENAL Aura Pro Karol 0.25 KAIROS 30 TIJOFI-TS-40 Nyrasis-500" />
                <meta name="description" content="KAIROS AURA PHARMACEUTICALS with just few products and continuously progressing under the table guidance of our Managing Experts. We are engaged in manufacturing and supplying tablets related to Nephrology.KAIROS AURA PHARMACEUTICALS is licensed by the drug controller for the marketing of pharmaceutical products.We have a spacious Godown for the storage of pharmaceutical products With experienced Packaging & Storage Managers where the products have been displayed and kept very properly with Latest Packing available nationwide. contact: +91 7846885019" />
                <meta name="robots" content="Kairos Aura Pharma, Kairos Aura ,Kairos 90, Kairos Alpha, Nephrosis, Kaira D3, Aura Plus, kaira-Q10, Aleska-DSR, NYRASIS, AURA PLUS, kaira Q10, Aleska DSR, SYNA-400, Neurocos Forte, TIJOFI-TS 100, TIJOFI-TS 10, TIJOFI-TS 20, SLOBIS LONG, AURA PRO RENAL, Aura Pro, Karol 0.25, KAIROS 30, TIJOFI-TS-40, Nyrasis-500" />
                <link rel="canonical" href="/about" />
            </Helmet>
        </>
    )
}
export default Seo;
